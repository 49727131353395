<template>
  <div class="container">
    <div v-if="client">
      <div class="row mb-0">
        <div class="col my-auto">
          <h4 class="text-primary fw-bold mb-0 client-tab-heading">Summary</h4>
        </div>
        <div class="col-auto ms-auto my-auto">
          <p class="mb-0 fw-bold">Assigned practitioner</p>
        </div>
        <div class="col-md-4 text-end my-auto">
          <select-user
            v-model="assignedPractitioner"
            @input="updateClientPractitioner"
          />
        </div>
        <div class="col-auto d-flex ps-0">
          <i
            class="far fa-info-circle text-primary my-auto fa-2x"
            v-tooltip:bottom="
              'Practitioner assigned to this client - will receive client activity notifications.'
            "
          ></i>
        </div>
      </div>

      <!--  -->
      <hr class="my-3" />

      <div class="row mb-4">
        <div class="col-lg-4">
          <div class="card border-0 shadow-none bg-light h-100">
            <div
              class="
                card-header
                bg-light
                text-dark
                fw-bold
                border-bottom border-primary
                mb-2
                border-0
              "
            >
              <div class="d-flex">
                <h5 class="my-auto text-primary fw-bold">Notes</h5>
                <router-link
                  class="btn btn-sm btn-outline-secondary ms-auto"
                  :to="'/clients/' + $route.params.id + '/notes'"
                >
                  View More
                </router-link>
              </div>
            </div>
            <div class="card-body p-2">
              <loading v-if="!notesLoaded"></loading>

              <ul
                class="list-group list-group-flush"
                v-else-if="reducedNotes.length"
              >
                <router-link
                  v-for="note in reducedNotes"
                  :key="note.id"
                  class="list-group-item cursor-pointer mb-2 wrap-ellipses"
                  :to="'/clients/' + $route.params.id + '/notes'"
                >
                  <p class="wrap-ellipses">
                    <i class="far fa-info-circle text-primary me-2"></i>
                    {{ note.body | truncate }}
                  </p>
                </router-link>
              </ul>
              <div class="text-center py-4" v-else>
                <p>No notes created</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="card border-0 shadow-none bg-light w-100 h-100">
            <div
              class="
                card-header
                bg-light
                text-dark
                fw-bold
                border-bottom border-primary
                mb-2
                border-0
              "
            >
              <div class="d-flex">
                <h5 class="my-auto text-primary fw-bold">Recent Orders</h5>
                <router-link
                  class="btn btn-sm btn-outline-secondary ms-auto"
                  :to="'/clients/' + $route.params.id + '/orders'"
                >
                  View More
                </router-link>
              </div>
            </div>
            <div class="card-body p-2">
              <loading v-if="!ordersLoaded"></loading>

              <div v-if="ordersLoaded">
                <table
                  v-if="orders.length"
                  class="table table-sm mb-0 bg-white table-borderless"
                >
                  <thead>
                    <tr>
                      <th scope="col" style="border-top: 0px">Title</th>
                      <th scope="col" style="border-top: 0px">Total</th>
                      <th scope="col" style="border-top: 0px">Status</th>
                      <th scope="col" style="border-top: 0px">Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o in recentOrders" :key="o.id">
                      <td>{{ o.title }}</td>
                      <td>{{ o.symbol }}{{ o.total.toFixed(2) }}</td>
                      <td>
                        <span class="text-capitalize">{{
                          o.status | formatStatus
                        }}</span>
                      </td>
                      <td>{{ o.created_at | formatDate }}</td>
                    </tr>
                  </tbody>
                </table>

                <div class="text-center py-4" v-else>
                  <p>No orders to show</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-4">
          <div class="card border-0 shadow-none bg-light h-100">
            <div
              class="
                card-header
                bg-light
                text-dark
                fw-bold
                border-bottom border-primary
                mb-2
                border-0
              "
            >
              <div class="d-flex">
                <h5 class="my-auto text-primary fw-bold">
                  Outstanding T&amp;Cs
                </h5>
                <router-link
                  class="btn btn-sm btn-outline-secondary ms-auto"
                  :to="'/clients/' + $route.params.id + '/terms'"
                >
                  View More
                </router-link>
              </div>
            </div>
            <div class="card-body p-2">
              <loading v-if="!agreementsLoaded"></loading>

              <ul
                class="list-group list-group-flush"
                v-else-if="outstandingAgreements.length"
              >
                <li
                  v-for="agreement in outstandingAgreements"
                  :key="agreement.id"
                  class="list-group-item cursor-pointer mb-2"
                >
                  <p>
                    <i class="far fa-exclamation-circle text-danger me-2"></i>
                    {{ agreement.term.title }}
                    <i
                      ><small class="ms-2">{{
                        "v" + agreement.term.version
                      }}</small></i
                    >
                  </p>
                </li>
              </ul>

              <div class="text-center py-4" v-else>
                <p>No outstanding T&amp;Cs</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="card border-0 shadow-none bg-light w-100 h-100">
            <div
              class="
                card-header
                bg-light
                text-dark
                fw-bold
                border-bottom border-primary
                mb-2
                border-0
              "
            >
              <div class="d-flex">
                <h5 class="my-auto text-primary fw-bold">
                  Upcoming Consultations
                </h5>
                <router-link
                  class="btn btn-sm btn-outline-secondary ms-auto"
                  :to="'/clients/' + $route.params.id + '/consultations'"
                >
                  View More
                </router-link>
              </div>
            </div>
            <div class="card-body p-2">
              <loading v-if="!consultationsLoaded"></loading>

              <table
                v-else-if="upcomingConsultations.length"
                class="table table-sm mb-0 table-borderless bg-white"
              >
                <thead>
                  <tr>
                    <th scope="col" style="border-top: 0px">Type</th>
                    <th scope="col" style="border-top: 0px">Appointment</th>
                    <th scope="col" style="border-top: 0px">Status</th>
                    <th scope="col" style="border-top: 0px">Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(c, index) in upcomingConsultations" :key="index">
                    <td class="text-capitalize">{{ c.service_name }}</td>
                    <td>
                      <span v-if="c.event_start">{{
                        c.event_start | formatDate
                      }}</span>
                      <span v-else>TBA</span>
                    </td>
                    <td class="text-capitalize">
                      {{ c.status | formatStatus }}
                    </td>
                    <td class="text-capitalize">
                      {{ c.order_status | formatStatus }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="text-center py-4" v-else>
                <p>No upcoming consultations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-4">
          <div class="card border-0 shadow-none bg-light h-100">
            <div
              class="
                card-header
                bg-light
                text-dark
                fw-bold
                border-bottom border-primary
                mb-2
                border-0
              "
            >
              <div class="d-flex">
                <h5 class="my-auto text-primary fw-bold">Outstanding Forms</h5>
                <router-link
                  class="btn btn-sm btn-outline-secondary ms-auto"
                  :to="'/clients/' + $route.params.id + '/forms'"
                >
                  View More
                </router-link>
              </div>
            </div>
            <div class="card-body p-2">
              <loading v-if="!formsLoaded"></loading>

              <ul
                class="list-group list-group-flush"
                v-else-if="outstandingForms.length"
              >
                <li
                  v-for="form in outstandingForms"
                  :key="form.id"
                  class="list-group-item cursor-pointer"
                >
                  <p class="wrap-ellipses">
                    <i class="far fa-exclamation-circle text-danger me-2"></i>
                    {{ form.title }}
                  </p>
                </li>
              </ul>

              <div class="text-center py-4" v-else>
                <p>No outstanding Forms</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="card border-0 shadow-none bg-light w-100 h-100">
            <div
              class="
                card-header
                bg-light
                text-dark
                fw-bold
                border-bottom border-primary
                mb-2
                border-0
              "
            >
              <div class="d-flex">
                <h5 class="my-auto text-primary fw-bold">
                  Unconfirmed Consultations
                </h5>
                <router-link
                  class="btn btn-sm btn-outline-secondary ms-auto"
                  :to="'/clients/' + $route.params.id + '/consultations'"
                >
                  View More
                </router-link>
              </div>
            </div>
            <div class="card-body p-2">
              <loading v-if="!consultationsLoaded"></loading>

              <table
                v-else-if="unconfirmedConsultations.length"
                class="table table-sm table-borderless bg-white"
              >
                <thead>
                  <tr>
                    <th scope="col" style="border-top: 0px">Type</th>
                    <th scope="col" style="border-top: 0px">Appointment</th>
                    <th scope="col" style="border-top: 0px">Status</th>
                    <th scope="col" style="border-top: 0px">Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(c, index) in unconfirmedConsultations"
                    :key="index"
                  >
                    <td class="text-capitalize">{{ c.service_name }}</td>
                    <td>
                      <span v-if="c.start">{{ c.start | formatDate }}</span>
                      <span v-else>TBA</span>
                    </td>
                    <td class="text-capitalize">
                      {{ c.status | formatStatus }}
                    </td>
                    <td class="text-capitalize">
                      {{ c.order_status | formatStatus }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="text-center py-4" v-else>
                <p>No unconfirmed consultations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import SelectUser from "@/views/components/users/SelectUser";

export default {
  data() {
    return {
      client: null,
      orders: [],
      consultations: [],
      agreements: [],
      forms: [],
      notes: [],

      ordersLoaded: false,
      consultationsLoaded: false,
      agreementsLoaded: false,
      formsLoaded: false,
      notesLoaded: false,
    };
  },
  methods: {
    fetchClientOrders() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/orders"
        )
        .then(({ data }) => {
          this.orders = data;
          this.ordersLoaded = true;
        });
    },
    fetchConsultations() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/consultations"
        )
        .then(({ data }) => {
          this.consultations = data;
          this.consultationsLoaded = true;
        });
    },
    fetchAgreements() {
      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/agreements"
        )
        .then(({ data }) => {
          this.agreements = data;
          this.agreementsLoaded = true;
        });
    },
    fetchForms() {
      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/form-requests"
        )
        .then(({ data }) => {
          this.forms = data;
          this.formsLoaded = true;
        });
    },
    fetchClientNotes() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/notes"
        )
        .then(({ data }) => {
          this.notes = data;
          this.notesLoaded = true;
        });
    },
    updateClientPractitioner(prac) {
      this.client.created_by = prac.id;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/clients/api/" + this.client.id,
          this.client
        )
        .then(({ data }) => {
          this.$EventBus.$emit("refreshClient");
          this.$EventBus.$emit("alert", data);
        });
    },
    setActive(tab) {
      this.$router.push("/clients/" + this.client.id + tab);
      this.$emit("updateActiveTab");
    },
    async fetchClientData() {
      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      // const { data } = await Clients.show(this.$route.params.id);

      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;
    await this.fetchClientData();
    this.fetchClientNotes();
    this.fetchClientOrders();
    this.fetchConsultations();
    this.fetchAgreements();
    this.fetchForms();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
    formatStatus(status) {
      if (status) {
        return status.replace(/_/g, " ");
      } else {
        return "";
      }
    },
    truncate(str) {
      if (str.length > 100) {
        str = str.substring(0, 100);
      }
      return str;
    },
  },
  computed: {
    assignedPractitioner() {
      return this.client?.practitioner;
    },
    reducedNotes: function () {
      return this.notes
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        .slice(0, 5);
    },
    recentOrders: function () {
      return this.orders
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .slice(0, 5);
    },
    upcomingConsultations() {
      return this.consultations
        .filter(function (consultation) {
          if (consultation.event_start) {
            return (
              moment(consultation.event_start) >= moment.now() &&
              consultation.status !== "cancelled" &&
              consultation.confirmed
            );
          } else {
            return false;
          }
        })
        .slice(0, 5);
    },
    unconfirmedConsultations() {
      return this.consultations
        .filter(function (consultation) {
          return !consultation.confirmed && consultation.status !== "cancelled";
        })
        .slice(0, 5);
    },
    outstandingAgreements() {
      return this.agreements
        .filter(function (agreement) {
          return !agreement.client_responded_date;
        })
        .slice(0, 5);
    },
    outstandingForms() {
      return this.forms
        .filter(function (form) {
          return !form.client_response_date;
        })
        .slice(0, 5);
    },
  },
  components: { SelectUser },
};
</script>

<style>
</style>
